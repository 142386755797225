<template>
    <div class="side-bar" :class="{ opened: showSidebar }">
      <div class="side-bar__layer" @click="toggleSideBar"/>
      <div class="side-bar__wrapper">
        <div class="side-bar__logo">
          <img src="/images/logo-1.png"/>
        </div>
        <div class="side-bar__user">
          <img src="/images/default-photo.png"/>
          <div class="side-bar__user-right">
            <h4>{{me.name}}</h4>
            <p>{{me.email}}</p>
            <button class="btn btn--green" @click="signout">{{$t('side.sign_out')}}</button>
            <p class="side-bar__mark"><b>{{$t('places.checkin_points')}}:</b>&nbsp;&nbsp;&nbsp; <span>{{checkInMark}}</span></p>
            <p class="side-bar__mark"><b>{{$t('quiz.question_points')}}:</b>&nbsp;&nbsp;&nbsp; <span>{{submissionMark}}</span></p>
          </div>
        </div>
        <div class="side-bar__header">{{$t('side.settings')}}</div>
        <div>
          <div class="side-bar__menu" @click="toggleChangePassword">
            <img src="/images/menu-password.png"/>
            <p>{{$t('side.change_password')}}</p>
          </div>
          <!--<div class="side-bar__menu">
            <img src="/images/menu-language.png"/>
            <p>{{$t('side.language')}}</p>
            <span>{{$t('side.english')}}</span>
          </div> -->
        </div>
        <div class="side-bar__header">{{$t('side.about')}}</div>
        <div>
          <div class="side-bar__menu" @click="showPdf('guide')">
            <img src="/images/menu-guide.png"/>
            <p>{{$t('side.user_guide')}}</p>
          </div>
          <div class="side-bar__menu" @click="showPdf('reference')">
            <img src="/images/menu-reference.png"/>
            <p>{{$t('side.references')}}</p>
          </div>
        </div>
        <div class="side-bar__header">{{$t('side.law')}}</div>
        <div>
          <div class="side-bar__menu" @click="showLink('legal')">
            <img src="/images/menu-legal.png"/>
            <p>{{$t('side.legal_notice')}}</p>
          </div>
          <div class="side-bar__menu" @click="showLink('privacy')">
            <img src="/images/menu-privacy.png"/>
            <p>{{$t('side.online_privacy')}}</p>
          </div>
          <div class="side-bar__menu" @click="showLink('information')">
            <img src="/images/menu-personal.png"/>
            <p>{{$t('side.personal_information')}}</p>
          </div>
        </div>

        <div class="side-bar__footer">
          <img src="/images/logo-2.png"/>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth, User } from '@/api/api'
import { isAndroid } from 'mobile-device-detect'

export default {
  name: 'SideBar',
  components: {},
  props: [
    'show'
  ],
  data () {
    return {
      showSidebar: false,
      checkInMark: 0,
      submissionMark: 0
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      try {
        const checkInMark = await User.getCheckInMark()

        this.checkInMark = checkInMark.data.checkInMark

        const submissionMark = await User.getSubmissionMark()

        this.submissionMark = submissionMark.data.submissionMark
      } catch (e) {
        this.$toast.error(this.$t('places.load_failed'))
      } finally {
        this.isLoading = false
      }
    },
    toggleSideBar () {
      this.showSidebar = !this.showSidebar

      this.$emit('toggle')
    },
    toggleChangePassword () {
      this.$emit('toggleChangePassword')
    },
    async signout () {
      try {
        await Auth.logout()
        this.$i18n.locale = 'zh-Hant'
        this.$store.dispatch('auth/setLanguage', 'zh-Hant')
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      } catch (err) {
        this.$toast.error(this.$t('side.logout_failed'))
      }
    },
    showPage (page) {
      this.toggleSideBar()
      this.$emit('showPage', page)
    },
    showPdf (page) {
      var langCode = this.language == 'en' ? 'en' : 'ch'
      if (isAndroid) {
        window.open('https://www.funfindhk.com/pdf/' + page + '-' + langCode + '.pdf', '_blank')
      } else {
        window.open('https://www.funfindhk.com/pdf/' + page + '-' + langCode + '.html', '_blank')
      }
    },
    showLink (page) {
      var link = ''
      if (page == 'legal') {
        link = this.language == 'en' ? 'https://www.lingkee.com/lkpg/en/legal_notice.html' : 'https://www.lingkee.com/lkpg/legal_notice.html'
        window.open(link, '_blank')
      } else if (page == 'privacy') {
        link = this.language == 'en' ? 'https://www.lingkee.com/lkpg/en/privacy_policy.html' : 'https://www.lingkee.com/lkpg/privacy_policy.html'
        window.open(link, '_blank')
      } else if (page == 'information') {
        link = this.language == 'en' ? 'https://www.lingkee.com/lkpg/en/personal_info.html' : 'https://www.lingkee.com/lkpg/personal_info.html'
        window.open(link, '_blank')
      }
    }
  },
  watch: {
    show () {
      this.showSidebar = this.show
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
    <div class="static-page">
      Privacy Page Content here
    </div>
</template>

<script>

export default {
  name: 'Privacy',
  components: {
  },
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
  @import "../style";
</style>

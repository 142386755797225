<template>
  <div class="lang-switch" :class="{ on: language === 'zh-Hant' }" @click="onChange">
    <div class="lang-switch__opt">EN</div>
    <div class="lang-switch__opt">CH</div>
    <div class="lang-switch__select"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'LangSwitch',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    })
  },
  methods: {
    onChange () {
      const newLang = this.language === 'en' ? 'zh-Hant' : 'en'

      this.$i18n.locale = newLang
      this.$store.dispatch('auth/switchLanguage', { language: newLang })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
  <div class="change-password-menu-wrap">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleModal()"/>
        </div>
        <h3>{{$t('auth.change_password')}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body">
        <div class="change-password">
          <div class="change-password__nav">
            <div class="change-password__nav-item">
              <div class="change-password__nav-item-content">
                  <div class="change-password_block mg-t-10">
                    <h3>{{$t('auth.password_complexity')}}</h3>
                  </div>
                  <form @submit.prevent="onChangePassword">
                    <div class="change-password_block">
                      <div class="form-element change-password_input">
                        <input type="password" v-model="form.currentpassword" @input="onInput('currentpassword', $event.target.value)" class="form-control" required name="currentpassword" autocomplete="off" :placeholder="$t('auth.currentpassword')" />
                      </div>
                    </div>
                    <div class="form-element change-password_bar">
                    </div>
                    <div class="change-password_block bottom-border">
                      <div class="form-element change-password_input bottom-border">
                        <input type="password" v-model="form.newpassword" @input="onInput('newpassword', $event.target.value)" class="form-control" required name="newpassword" autocomplete="off" :placeholder="$t('auth.newpassword')"/>
                      </div>
                      <div class="form-element change-password_input">
                        <input type="password" v-model="form.cnewpassword" @input="onInput('cnewpassword', $event.target.value)" class="form-control" required name="cnewpassword" autocomplete="off" :placeholder="$t('auth.confirm_newpassword')"/>
                      </div>
                    </div>
                    <div class="mg-t-100 change-password_block">
                        <button type="submit" class="btn btn--green blocked">{{$t('global.save')}}</button>
                        <!--  <RouterLink to="/register" class="mg-t-10 btn btn--green-outline blocked">{{$t('auth.register')}}</RouterLink>  -->
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
        <Loading v-if="isLoading"/>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/loading/Loading'
import { Auth } from '@/api/api'

export default {
  name: 'ChangePassword',
  components: { Loading },
  props: [
    'show'
  ],
  data () {
    return {
      form: {
        currentpassword: '',
        newpassword: '',
        cnewpassword: ''
      },
      showModal: false,
      isLoading: false,
      errors: [],
      form_validation: true
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    })
  },
  mounted () {
  },
  methods: {
    onInput (key, value) {
      this.form = {
        ...this.form,
        [key]: value
      }
    },
    toggleModal () {
      this.showModal = !this.showModal

      this.$emit('toggle')
    },
    async onChangePassword () {
      this.form_validation = true
      this.isLoading = true

      try {
        if (this.form.newpassword !== this.form.cnewpassword) {
          this.$toast.error(this.$t('auth.confirm_password_failed'))
          this.form_validation = false
        } if (this.form.newpassword.length < 4) {
          this.$toast.error(this.$t('auth.password_must_be_at_least_4_characters'))
          this.form_validation = false
        }

        if (this.form_validation == false) {
          this.$toast.error(this.$t('auth.change_password_failed'))
          return false
        } else {
          const { data } = await Auth.changePassword(this.form.currentpassword, this.form.newpassword, this.form.cnewpassword)
          console.log(data)

          if (data.status == 0) {
            if (data?.error) {
              if (data.error?.currentpassword) {
                console.log(data.error.currentpassword[0])
                this.$toast.error(this.$t('auth.' + data.error.currentpassword[0]))
              } else {
                this.$toast.error(this.$t('auth.change_password_failed'))
              }
            } else {
              this.$toast.error(this.$t('auth.change_password_failed'))
            }
          } else {
            this.$toast.success(this.$t('auth.change_password_succeeded'))
            this.$emit('toggle')
          }
        }
      } catch (e) {
        this.$toast.error(e)
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    show () {
      this.showModal = this.show
      this.form.currentpassword = ''
      this.form.newpassword = ''
      this.form.cnewpassword = ''
    }
  }
}
</script>

<style scoped lang="scss">
  @import "changePassword";
</style>

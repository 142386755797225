<template>
  <div class="gallery-menu-wrap">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleModal()"/>
        </div>
        <h3>{{$t('gallery.gallery')}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body">
        <div class="gallery" style="overflow-y:scroll!important;height:85vh!important">
          <div class="gallery__nav">
            <div class="gallery__nav-item" @click="changePage(1)" :class="{ active: page === 1 }">
              <div class="gallery__nav-item-content">
                <h3>{{$t('gallery.your_photos')}}</h3>
                <p>{{giftsOwned}}</p>
              </div>
            </div>
            <div class="gallery__nav-item" @click="changePage(2)" :class="{ active: page === 2 }">
              <div class="gallery__nav-item-content">
                <h3>{{$t('gallery.fragments')}}</h3>
                <p>{{me.points}}</p>
              </div>
              <img src="/images/film.png"/>
            </div>
          </div>
          <div v-if="page === 1" class="gallery__content">
            <div class="gallery__imgs">
              <div v-for="(gift, index) in gifts" :key="gift.id" class="gallery__img exists" @click="enlargeImage(index)">
                <img :src="gift.picture"/>
                <p>{{giftName(gift)}}</p>
              </div>
            </div>
          </div>
          <div v-if="page === 2" class="gallery__content">
            <img src="/images/camera.png" class="gallery__cam mg-b-20"/>
            <button class="btn btn--orange blocked" :class="{ disabled: !canRedeem }" :disabled="!canRedeem" @click="redeem">{{$t('gallery.take_photo')}}</button>
            <div class="flex align-center justify-center mg-t-20">
              <img src="/images/film.png" class="w-50 pd-r-10"/>
              <p>{{$t('gallery.fragments_spend')}}</p>
            </div>
          </div>
        </div>
        <Loading v-if="isLoading"/>
      </div>
    </vue-final-modal>

    <vue-final-modal
      v-model="showImgModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="showImgModal = false"/>
        </div>
        <h3>{{giftName(enlargedGift)}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body">
        <img :src="enlargedGift?.picture" class="expanded-img" />
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/loading/Loading'
import { Gifts } from '@/api/api'

export default {
  name: 'Gallery',
  components: { Loading },
  props: [
    'show'
  ],
  data () {
    return {
      showModal: false,
      isLoading: true,
      gifts: [],
      giftsOwned: 0,
      showImgModal: false,
      imgModalIndex: 0,
      page: 1
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    }),
    canRedeem () {
      return this.me.points >= 5
    },
    hasMoreGifts () {
      return this.giftsOwned < this.gifts.length
    },
    enlargedGift () {
      return this.gifts[this.imgModalIndex]
    }
  },
  mounted () {
  },
  methods: {
    enlargeImage (index) {
      this.imgModalIndex = index
      this.showImgModal = true
    },
    exists (gift) {
      return !!gift.students.length
    },
    giftName (gift) {
      return gift?.name[this.language] || gift?.name[Object.keys(gift.name)[0]]
    },
    toggleModal () {
      this.showModal = !this.showModal

      this.$emit('toggle')
    },
    changePage (page) {
      this.page = page
    },
    async loadGifts () {
      try {
        const gifts = await Gifts.all()

        this.giftsOwned = gifts.data.length
        this.gifts = gifts.data
      } catch (err) {
        this.$toast.error(this.$t('gallery.load_failed'))
      } finally {
        this.isLoading = false
      }
    },
    async redeem () {
      this.isLoading = true
      try {
        const gifts = await Gifts.redeem()
        // let count = 0

        // gifts.data.forEach((g) => {
        //   if (g.students.length) {
        //     count++
        //   }
        // })

        this.giftsOwned = gifts.length
        this.gifts = gifts.data
        this.page = 1

        this.$store.dispatch('auth/removePoints', {
          points: 5
        })
      } catch (err) {
        this.$toast.error(this.$t('gallery.redeem_failed'))
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    show () {
      this.showModal = this.show

      if (this.showModal) {
        this.isLoading = true
        this.loadGifts()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
  <div class="menu">
    <!--<div class="menu__left">

    </div>
    -->
    <div class="menu__right">
    <!--  <div @click="clickCallback" class="menu__item">
        <div class="menu__item-img">
          <img src="/images/save.png" class="menu__bw" alt=""/>
          <img src="/images/save-c.png" class="menu__cl" alt=""/>
        </div>
        <span>{{$t('global.shortcut')}}</span>
      </div> -->
      <router-link to="/places" @click="menuClicked" :class="{ 'router-link-active': isLinkActive('/places') }" class="menu__item">
        <div class="menu__item-img">
          <img src="/images/places.png" class="menu__bw" alt=""/>
          <img src="/images/places-c.png" class="menu__cl" alt=""/>
        </div>
        <span>{{$t('global.place')}}</span>
      </router-link>
      <router-link to="/histories" @click="menuClicked" :class="{ 'router-link-active': isLinkActive('/histories') }" class="menu__item">
        <div class="menu__item-img">
          <img src="/images/histories.png" class="menu__bw" alt=""/>
          <img src="/images/histories-c.png" class="menu__cl" alt=""/>
        </div>
        <span>{{$t('global.article')}}</span>
      </router-link>
      <div class="menu__item" @click.prevent="toggleSideBar">
        <div class="menu__item-img">
          <img src="/images/settings.png" class="menu__bw" alt=""/>
          <img src="/images/settings-c.png" class="menu__cl" alt=""/>
        </div>
        <span>{{$t('global.setting')}}</span>
      </div>
    </div>
    <div class="menu__album" @click="toggleGallery">
      <img src="/images/photo.png" alt=""/>
      <span>{{$t('global.album')}}</span>
    </div>
    <gallery :show="isGalleryOpened" @toggle="toggleGallery"/>
    <side-bar :show="isSideBarOpened" @toggle="toggleSideBar" @toggleChangePassword="toggleChangePassword" @showPage="showPage"/>
    <static-page :show="!!page" :page="page" @toggle="showPage(null)"/>
    <change-password :show="isChangePasswordOpened" @toggle="toggleChangePassword" />
  </div>
</template>

<script>
import StaticPage from '@/pages/static/page'
import Gallery from '@/components/gallery/gallery'
import SideBar from '@/components/sideBar/sideBar'
import ChangePassword from '@/components/popup/changePassword'
import { mapGetters } from 'vuex'

export default {
  name: 'BottomMenu',
  components: {
    StaticPage,
    Gallery,
    SideBar,
    ChangePassword
  },
  props: [
  ],
  data () {
    return {
      isGalleryOpened: false,
      isSideBarOpened: false,
      isChangePasswordOpened: false,
      deferredPrompt: null,
      page: null
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    })
  },
  mounted () {
    this.captureEvent()
    if (this.$route.query?.static) {
      this.page = this.$route.query?.static
    }

    this.$i18n.locale = this.language
    console.log('bottom Menu i18 locale ' + this.$i18n.locale)
    console.log('bottom Menu this.language' + this.language)
  },
  methods: {
    isLinkActive (path) {
      return this.$route.path.includes(path)
    },
    toggleGallery () {
      this.isGalleryOpened = !this.isGalleryOpened
    },
    toggleSideBar () {
      this.isSideBarOpened = !this.isSideBarOpened
    },
    toggleChangePassword () {
      this.isSideBarOpened = false
      this.isChangePasswordOpened = !this.isChangePasswordOpened
    },
    showPage (page) {
      this.page = page
    },
    menuClicked () {
      this.emitter.emit('menuClicked')
    },
    captureEvent () {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.deferredPrompt = e
      })
    },
    clickCallback () {
      this.deferredPrompt.prompt()
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.$toast.success(this.$t('global.added'))
        }
        this.deferredPrompt = null
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
  <div class="header">
    <div class="header__left">
      <img src="/images/logo-1.png" alt="" class="header__logo"/>
    </div>
    <div class="header__right">
      <lang-switch/>
    </div>
  </div>
</template>

<script>
import langSwitch from '@/components/langSwitch/langSwitch'
export default {
  name: 'Header',
  components: { langSwitch },
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
  <div class="search-bar">
    <form class="search-bar__input" @submit.prevent="search">
      <input
        type="text"
        v-model="searchTerm"
        :placeholder="placeHolderText">
      <img src="/images/search.png" alt=""/>
    </form>
    <img src="/images/filter.png" alt="" class="search-bar__filter" @click="toggleModal"/>

    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content w-350"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleModal" v-if="filterPage === 1"/>
          <img src="/images/back-icon.png" @click="changeFilterPage(1)" v-else/>
        </div>
        <h3>{{ $t('global.search') }}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body">
        <div class="filters" v-if="filterPage === 1">
          <div class="filters__item" @click="changeFilterPage(2)">
            <span>{{$t('global.district')}}</span>
            <img src="/images/arrow-right.png"/>
          </div>
          <div class="filters__item" @click="changeFilterPage(3)">
            <span>{{$t('global.time')}}</span>
            <img src="/images/arrow-right.png"/>
          </div>
          <div class="filters__item" @click="changeFilterPage(4)">
            <span>{{$t('global.grade')}}</span>
            <img src="/images/arrow-right.png"/>
          </div>
          <div class="filters__item">
            <span>{{$t('global.nationalEducation')}}</span>
            <div class="filters__check" :class="{ selected: nationalEducation }"  @click="na"/>
          </div>
        </div>
        <div class="filters" v-if="filterPage === 2">
          <div v-for="area in areas" :key="area.id" class="filters__wrap">
            <div class="filters__item" @click="area.expanded = !area.expanded">
              <span>{{areaName(area)}}</span>
              <img src="/images/arrow-right.png" v-if="!area.expanded"/>
              <img src="/images/arrow-down.png" v-else/>
            </div>
            <div class="filters__subs" v-if="area.expanded">
              <div v-for="district in area.districts" :key="district.id" class="filters__sub">
                <span>{{areaName(district)}}</span>
                <div class="filters__check" :class="{ selected: district.selected }"  @click="district.selected = !district.selected"/>
              </div>
            </div>
          </div>
        </div>
        <div class="filters" v-if="filterPage === 3">
          <div v-for="tag in times" :key="tag.id" class="filters__wrap">
            <div class="filters__item">
              <span>{{areaName(tag)}}</span>
              <div class="filters__check" :class="{ selected: tag.selected }"  @click="tag.selected = !tag.selected"/>
            </div>
          </div>
        </div>
        <div class="filters" v-if="filterPage === 4">
          <div v-for="grade in grades" :key="grade.id" class="filters__wrap">
            <div class="filters__item">
              <span>{{areaName(grade)}}</span>
              <div class="filters__check" :class="{ selected: grade.selected }"  @click="grade.selected = !grade.selected"/>
            </div>
          </div>
        </div>
        <div class="pd-15 mg-t-10">
          <button class="btn btn--red-outline blocked mg-b-5" @click="reset">{{$t('global.reset')}}</button>
          <button class="btn btn--green blocked" @click="search">{{$t('global.search')}}</button>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { Districts, Tags, Grades } from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchBar',
  components: {},
  props: [
    'location',
    'placeHolderText'
  ],
  data () {
    return {
      showModal: false,
      areas: [],
      times: [],
      grades: [],
      nationalEducation: false,
      searchTerm: null,
      tagId: null,
      tagName: null,
      filterPage: 1
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    }),
    modalTitle () {
      switch (this.filterPage) {
        case 1:
          return this.$t('global.search')
        case 2:
          return this.$t('global.search')
        case 4:
          return this.$t('global.search')
        case 3:
        default:
          return this.$t('global.search')
      }
    }
  },
  async mounted () {
    this.emitter.on('menuClicked', this.reset)
    await this.loadData()
    this.checkUrl()
  },
  beforeUnmount () {
    this.emitter.off('menuClicked', this.reset)
  },
  methods: {
    na () {
      this.nationalEducation = !this.nationalEducation
      console.log(this.nationalEducation)
    },
    async loadData () {
      try {
        const [districts, tags, forms] = await Promise.all([
          Districts.all(),
          Tags.all('time'),
          Grades.all()

        ])

        this.areas = districts.data
        this.times = tags.data

        /* this.grades[0] = JSON.parse('{"id":"0", "name":{"en": "Grade 1", "zh-Hant": "中一"}}')
        this.grades[1] = JSON.parse('{"id":"1", "name":{"en": "Grade 2", "zh-Hant": "中二"}}')
        this.grades[2] = JSON.parse('{"id":"2", "name":{"en": "Grade 3", "zh-Hant": "中三"}}')
        console.log(this.grades[0])  */

        this.grades = forms.data
      } catch (e) {
        console.error(e)
      }
    },
    areaName (area) {
      return area.name[this.language] || area.name[Object.keys(area.name)[0]]
    },
    checkUrl () {
      this.searchTerm = this.$route.query?.searchTerm
      this.tagId = this.$route.query?.tagId
      this.tagName = this.$route.query?.tagName

      const districtIds = this.$route.query?.districts || []

      this.areas = this.areas.map((a) => {
        a.districts = a.districts.map((d) => {
          if (districtIds.includes(d.id)) {
            d.selected = true
          }

          return d
        })
        return a
      })

      const tags = this.$route.query?.tags || []

      this.times = this.times.map((t) => {
        if (tags.includes(t.id)) {
          t.selected = true
        }

        return t
      })

      this.search()
    },
    toggleModal () {
      this.showModal = !this.showModal
      this.filterPage = 1
    },
    changeFilterPage (page) {
      this.filterPage = page
    },
    search () {
      const districts = []
      const tags = []
      const forms = []

      this.areas.forEach((a) => {
        a.districts.forEach((d) => {
          if (d.selected) {
            districts.push(d.id)
          }
        })
      })

      this.times.forEach((t) => {
        if (t.selected) {
          tags.push(t.id)
        }
      })

      this.grades.forEach((g) => {
        if (g.selected) {
          forms.push(g.id)
        }
      })

      this.$emit('search', {
        filters: {
          searchTerm: this.searchTerm?.length ? this.searchTerm : null,
          districts: districts.length ? districts : null,
          tags: tags.length ? tags : null,
          forms: forms.length ? forms : null,
          tagId: this.tagId,
          nationalEducation: this.nationalEducation,
          ...(this.location || {})
        }
      })

      this.showModal = false
    },
    reset () {
      this.times = this.times.map((t) => {
        t.selected = false

        return t
      })
      this.areas = this.areas.map((a) => {
        a.districts = a.districts.map((d) => {
          d.selected = false

          return d
        })

        return a
      })

      this.grades.map((g) => {
        g.selected = false
        return g
      })

      this.searchTerm = null
      this.tagId = null
      this.tagName = null
      this.nationalEducation = false

      this.$emit('search', {
        filters: {
          searchTerm: null,
          districts: null,
          tags: null,
          tagId: null,
          nationalEducation: false
        }
      })

      this.showModal = false
    }
  },
  watch: {
    searchTerm (newVal, oldVal) {
      if (oldVal === this.tagName && newVal !== this.tagName) {
        this.tagName = null
        this.tagId = null
      }
    },
    '$router' (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>

<template>
    <div class="static-page">
      Legal Page Content here {{ linkText }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Legal',
  components: {
  },
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    }),
    linkText () {
      return 'hello ' + this.language
    }
  },
  mounted () {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
  @import "../style";
</style>

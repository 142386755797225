<template>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleModal()"/>
        </div>
        <h3>{{pageTitle}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body static-page">
        <component :is="page"/>
        <Loading v-if="isLoading"/>
      </div>
    </vue-final-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from '@/components/loading/Loading'
import Guide from './pages/guide'
import Reference from './pages/reference'
import Legal from './pages/legal'
import Privacy from './pages/privacy'
import Information from './pages/information'

export default {
  name: 'StaticPage',
  components: {
    Loading,
    Guide,
    Reference,
    Legal,
    Privacy,
    Information
  },
  props: [
    'show',
    'page'
  ],
  data () {
    return {
      showModal: false,
      pages: {
        guide: {
          title: this.$t('static.user_guide')
        },
        reference: {
          title: this.$t('static.references')
        },
        legal: {
          title: this.$t('static.legal_notice')
        },
        privacy: {
          title: this.$t('static.online_privacy')
        },
        information: {
          title: this.$t('static.personal_information')
        }
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language',
      me: 'auth/me'
    }),
    pageTitle () {
      console.log('Modal title' + this.pages[this.page]?.title)
      console.log('Modal langauage' + this.language)
      // return this.pages[this.page]?.title
      if (this.page == 'guide') {
        return this.$t('static.user_guide')
      } else if (this.page == 'reference') {
        return this.$t('static.references')
      } else if (this.page == 'legal') {
        return this.$t('static.legal_notice')
      } else if (this.page == 'privacy') {
        return this.$t('static.online_privacy')
      } else if (this.page == 'information') {
        return this.$t('static.personal_information')
      } else {
        return ''
      }
    }
  },
  mounted () {
  },
  methods: {
    toggleModal () {
      this.showModal = !this.showModal

      this.$emit('toggle')
    }
  },
  watch: {
    show () {
      this.showModal = this.show
    }
  }
}
</script>

<style scoped lang="scss">
</style>

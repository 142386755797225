<template>
  <div class="map">
    <div ref="map" class="map__iframe"/>
    <div class="map__center" @click="goToUserLocation">
      <img src="/images/center-map.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',
  props: [
    'results',
    'viewOnly',
    'item'
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  async mounted () {
    this.markers = null
    this.userMarker = null

    if (!this.show) {
      if (navigator.geolocation) {
        this.emitter.on('menuClicked', this.goToUserLocation)
        this.getUserLocation()
        this.locationInterval = window.setInterval(this.getUserLocation, 1000)
        navigator.geolocation.getCurrentPosition((pos) => this.initialiseMap(pos.coords.latitude, pos.coords.longitude), async () => {
          const pos = await this.loadAddress()
          this.initialiseMap(pos.lat, pos.long)
        })
      } else {
        const pos = await this.loadAddress()
        this.initialiseMap(pos.lat, pos.long)
      }
    }
  },
  beforeUnmount () {
    this.emitter.off('menuClicked', this.goToUserLocation)
    this.resetMarkers()
    window.clearInterval(this.locationInterval)
  },
  methods: {
    calculateDistance (lat1, lon1) {
      if (!this.item) {
        return 0
      }

      const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
      }

      const lat2 = this.item.latitude
      const lon2 = this.item.longitude
      const R = 6371
      const dLat = deg2rad(lat2 - lat1)
      const dLon = deg2rad(lon2 - lon1)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c * 1000
      return d
    },
    getUserLocation () {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.$emit('userLocation', {
          lat: pos.coords.latitude,
          long: pos.coords.longitude,
          distance: this.calculateDistance(pos.coords.latitude, pos.coords.longitude)
        })

        this.attachUserLocation(pos.coords.latitude, pos.coords.longitude)
      }, () => {
        console.error('No access to user location')
      })
    },
    loadAddress () {
      return new Promise(resolve => {
        this.geocoder = new window.google.maps.Geocoder()

        this.geocoder.geocode({ address: '14/F Zung Fu Industrial Bldg 1067 King\'s Rd Quarry Bay, Hong Kong' }, (results, status) => {
          if (status === 'OK') {
            resolve({
              lat: results[0].geometry.location.lat(),
              long: results[0].geometry.location.lng()
            })
          } else {
            resolve({
              lat: 0,
              long: 0
            })
          }
        })
      })
    },
    initialiseMap (lat, long) {
      this.$emit('locationSet', {
        lat,
        long
      })
      this.mapLoaded = true

      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: 14,
        center: {
          lat: lat,
          lng: long
        },
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true
      })
    },
    attachMarker (result) {
      const marker = new window.google.maps.Marker({
        map: this.map,
        url: '/places/' + result.id,
        icon: '/images/marker.png',
        position: {
          lat: result.latitude,
          lng: result.longitude
        }
      })
      window.google.maps.event.addListener(marker, 'click', () => {
        this.$router.push(marker.url)
      })

      return marker
    },
    attachUserLocation (lat, lon) {
      if (this.map) {
        if (this.userLon !== lon || this.userLat !== lat) {
          this.userLat = lat
          this.userLon = lon
          this.userMarker && this.userMarker.setMap(null)
          this.userMarker = new window.google.maps.Marker({
            map: this.map,
            icon: '/images/my-location.png',
            position: {
              lat: lat,
              lng: lon
            }
          })
        }
      }
    },
    showResults () {
      this.resetMarkers()

      if (this.results?.length) {
        this.markers = []
        this.results.forEach((r) => {
          const marker = this.attachMarker(r)

          this.markers.push(marker)
        })

        // this.centerMap(this.results[0])
      }
    },
    centerMap (result) {
      if (this.map) {
        this.map.setCenter({
          lat: result.latitude,
          lng: result.longitude
        })
      }
    },
    goToUserLocation () {
      this.centerMap({
        latitude: this.userLat || 0,
        longitude: this.userLon || 0
      })
    },
    resetMarkers () {
      if (this.markers) {
        this.markers.forEach((m) => {
          window.google.maps.event.clearListeners(m, 'click')
          m.setMap(null)
        })

        this.markers = null
      }
    }
  },
  watch: {
    results () {
      this.showResults()
    },
    item () {
      if (this.item) {
        this.attachMarker(this.item)
        this.centerMap(this.item)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
